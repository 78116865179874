import { Ability } from '@casl/ability'

export const ability = new Ability()

export const abilityPlugin = (store) => {
    ability.update(store.state.rules)

    return store.subscribe((mutation) => {
        switch (mutation.type) {
            case 'authUser':
                //console.log(mutation.payload.rules)
                ability.update(mutation.payload.rules)
                break
            case 'clearAuthData':
                ability.update([])
                break
        }
    })
}