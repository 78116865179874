import Vue from 'vue'
import Vuex from 'vuex'

import auth from './modules/auth'
import booking from './modules/booking'
import bookings from './modules/bookings'
import customer from './modules/customer'
import { abilityPlugin, ability as appAbility } from './ability'

Vue.use(Vuex)

export const ability = appAbility
export const store = new Vuex.Store({
    plugins: [
        abilityPlugin
    ],
    modules: {
        auth,
        booking,
        bookings,
        customer
    }
})