import axios from '../../axios-auth.js'
import globalAxios from 'axios'

import router from '../../routes/starterRouter'

const state = {
    idToken: null,
    user: null,
    rules: []
}

const mutations = {
    authUser(state, userData) {
        state.idToken = userData.token
        state.user = userData.user
        state.rules = userData.rules
    },
    clearAuthData(state) {
        state.idToken = null
        state.user = null
        state.rules = []
    }
}

const actions = {
    setLogoutTimer({ commit }, expirationTime) {
        setTimeout(() => {
            commit('clearAuthData')
        }, expirationTime * 1000)
    },
    /*signup({ commit, dispatch }, authData) {
        axios.post('registerAdmin', authData)
            .then(res => {
                console.log(res)
                commit('authUser', {
                    token: res.data.idToken,
                    userId: res.data.localId
                })
                const now = new Date()
                const expirationTime = new Date(now.getTime() + 3600 * 1000)
                localStorage.setItem('userId', res.data.userId)
                localStorage.setItem('expirationTime', expirationTime)
                dispatch('storeUser', authData)
                dispatch('setLogoutTimer', 3600) //res.data.expiresIn
            })
            .catch(error => console.log(error))
    },*/
    login({ commit, dispatch }, authData) {
        //console.log(authData)
        return axios.post('login', authData)
            .then(res => {
                //console.log(res)
                const now = new Date()
                const expirationTime = new Date(now.getTime() + 3600 * 1000)
                localStorage.setItem('user', JSON.stringify(res.data.user))
                localStorage.setItem('token', res.data.token)
                localStorage.setItem('rules', JSON.stringify(res.data.rules))
                localStorage.setItem('expirationTime', expirationTime)
                commit('authUser', {
                    token: res.data.token,
                    user: res.data.user,
                    rules: res.data.rules
                })
                dispatch('setLogoutTimer', 3600) //res.data.expiresIn
                router.replace('/')
                return {
                    success: true
                }
            })
            .catch(error => {
                console.log(error)
                return {
                    success: false,
                    error: error
                }
            })
    },
    tryAutoLogin({ commit }) {
        const token = localStorage.getItem('token')
        const user = JSON.parse(localStorage.getItem('user'))
        const rules = JSON.parse(localStorage.getItem('rules'))
        if (user)
            console.log('Auto loging user: ' + user.first_name)
        if (!token) {
            console.log('No user, exiting!')
            return
        }
        const expirationTime = localStorage.getItem('expirationTime')
        const now = new Date();
        if (now >= expirationTime) {
            console.log('Expired, exiting')
            return
        }
        commit('authUser', {
                token,
                user,
                rules
            })
            //router.replace('/')
    },
    logout({ commit }) {
        commit('clearAuthData')
        localStorage.removeItem('token')
        localStorage.removeItem('expirationTime')
        localStorage.removeItem('user')
        localStorage.removeItem('rules')
        router.replace('/login')
    },
    storeUser({ commit, state }, userData) {
        if (!state.idToken)
            return
        globalAxios.post('', userData)
            .then(res => console.log(res))
            .catch(error => console.log(error))
    },
    fetchUser({ commit, state }) {
        if (!state.idToken)
            return
        axios.get('users')
            .then(res => {
                console.log(res)
                const data = res.data
                const users = []
                for (let key in data) {
                    const user = data[key]
                    user.id = key
                    users.push(user)
                }
                console.log(users)
                commit('storeUser', users[0])
            })
            .catch(error => console.log(error))
    }
}

const getters = {
    user(state) {
        return state.user
    },
    isAuthenticated(state) {
        return (state.idToken !== null) && (state.user !== null)
    }
}

export default {
    namespace: true,
    state,
    mutations,
    actions,
    getters
}