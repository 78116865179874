<template>
  <div>
    <base-header class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0">Stats</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </div>
        <!--<div class="col-lg-6 col-5 text-right">
          <base-button size="sm" type="neutral">New</base-button>
          <base-button size="sm" type="neutral">Filters</base-button>
        </div>-->
      </div>
      <!-- Card stats -->
      <div class="row">
        <div class="col-xl-3 col-md-6">
          <stats-card v-if="currentStats.current" :title="currentStats.current.noTotal + ' completed till ' + moment().format('DD.MM')"
                      type="gradient-red"
                      :sub-title="'€' + currentStats.current.eurTotal"
                      icon="ni ni-money-coins">

            <template slot="footer">
              <span class="text-nowrap mr-2" :class="{'text-success': currentStats.change.eurTotal > 0, 'text-danger': currentStats.change.eurTotal <= 0}"><i class="fa" :class="{'fa-arrow-up': currentStats.change.eurTotal > 0, 'fa-arrow-down': currentStats.change.eurTotal <= 0}"></i>{{currentStats.change.eurTotal.toFixed(0)}}%</span>
              <span>earn €{{currentStats.compare.eurTotal}}, </span>
              <span class="text-nowrap mr-2" :class="{'text-success': currentStats.change.noTotal > 0, 'text-danger': currentStats.change.noTotal <= 0}"><i class="fa" :class="{'fa-arrow-up': currentStats.change.noTotal > 0, 'fa-arrow-down': currentStats.change.noTotal <= 0}"></i>{{currentStats.change.noTotal.toFixed(0)}}%</span>
              <span>#{{currentStats.compare.noTotal}}</span>
            </template>
          </stats-card>
        </div>
        <div class="col-xl-3 col-md-6">
          <stats-card v-if="projectedStats.current" :title="projectedStats.current.noTotal + ' booked for ' + moment().format('YYYY')"
                      type="gradient-red"
                      :sub-title="'€' + projectedStats.current.eurTotal"
                      icon="ni ni-money-coins">

            <template slot="footer">
              <span class="text-nowrap mr-2" :class="{'text-success': projectedStats.change.eurTotal > 0, 'text-danger': projectedStats.change.eurTotal <= 0}"><i class="fa" :class="{'fa-arrow-up': projectedStats.change.eurTotal > 0, 'fa-arrow-down': projectedStats.change.eurTotal <= 0}"></i>{{projectedStats.change.eurTotal.toFixed(0)}}%</span>
              <span>earn €{{projectedStats.compare.eurTotal}}, </span>
              <span class="text-nowrap mr-2" :class="{'text-success': projectedStats.change.noTotal > 0, 'text-danger': projectedStats.change.noTotal <= 0}"><i class="fa" :class="{'fa-arrow-up': projectedStats.change.noTotal > 0, 'fa-arrow-down': projectedStats.change.noTotal <= 0}"></i>{{projectedStats.change.noTotal.toFixed(0)}}%</span>
              <span>#{{projectedStats.compare.noTotal}}</span>
            </template>
          </stats-card>
        </div>
        <div class="col-xl-3 col-md-6">
          <stats-card v-if="currentStats.current" :title="currentStats.current.noRentals + ' rentals completed, ' + currentStats.current.noRentalsWithSkipper + ' w/skipper'"
                      type="gradient-orange"
                      :sub-title="'€' + currentStats.current.eurRentals"
                      icon="ni ni-chart-bar-32">

            <template slot="footer">
              <span class="text-nowrap mr-2" :class="{'text-success': currentStats.change.eurRentals > 0, 'text-danger': currentStats.change.eurRentals <= 0}"><i class="fa" :class="{'fa-arrow-up': currentStats.change.eurRentals > 0, 'fa-arrow-down': currentStats.change.eurRentals <= 0}"></i>{{currentStats.change.eurRentals.toFixed(0)}}%</span>
              <span>earn €{{currentStats.compare.eurRentals}}, </span>
              <span class="text-nowrap mr-2" :class="{'text-success': currentStats.change.noRentals > 0, 'text-danger': currentStats.change.noRentals <= 0}"><i class="fa" :class="{'fa-arrow-up': currentStats.change.noRentals > 0, 'fa-arrow-down': currentStats.change.noRentals <= 0}"></i>{{currentStats.change.noRentals.toFixed(0)}}%</span>
              <span>#{{currentStats.compare.noRentals}}</span>
            </template>
          </stats-card>
        </div>
        <div class="col-xl-3 col-md-6">
          <stats-card v-if="projectedStats.current" :title="projectedStats.current.noRentals + ' rentals booked, ' + projectedStats.current.noRentalsWithSkipper + ' w/skipper'"
                      type="gradient-orange"
                      :sub-title="'€' + projectedStats.current.eurRentals"
                      icon="ni ni-chart-bar-32">

            <template slot="footer">
              <span class="text-nowrap mr-2" :class="{'text-success': projectedStats.change.eurRentals > 0, 'text-danger': projectedStats.change.eurRentals <= 0}"><i class="fa" :class="{'fa-arrow-up': projectedStats.change.eurRentals > 0, 'fa-arrow-down': projectedStats.change.eurRentals <= 0}"></i>{{projectedStats.change.eurRentals.toFixed(0)}}%</span>
              <span>earn €{{projectedStats.compare.eurRentals}}, </span>
              <span class="text-nowrap mr-2" :class="{'text-success': projectedStats.change.noRentals > 0, 'text-danger': projectedStats.change.noRentals <= 0}"><i class="fa" :class="{'fa-arrow-up': projectedStats.change.noRentals > 0, 'fa-arrow-down': projectedStats.change.noRentals <= 0}"></i>{{projectedStats.change.noRentals.toFixed(0)}}%</span>
              <span>#{{projectedStats.compare.noRentals}}</span>
            </template>
          </stats-card>
        </div>
        <div class="col-xl-3 col-md-6">
          <stats-card v-if="currentStats.current" :title="currentStats.current.noTours + ' tours completed'"
                      type="gradient-green"
                      :sub-title="'€' + currentStats.current.eurTours"
                      icon="ni ni-cart">

            <template slot="footer">
              <span class="text-nowrap mr-2" :class="{'text-success': currentStats.change.eurTours > 0, 'text-danger': currentStats.change.eurTours <= 0}"><i class="fa" :class="{'fa-arrow-up': currentStats.change.eurTours > 0, 'fa-arrow-down': currentStats.change.eurTours <= 0}"></i>{{currentStats.change.eurTours.toFixed(0)}}%</span>
              <span>earn €{{currentStats.compare.eurTours}}, </span>
              <span class="text-nowrap mr-2" :class="{'text-success': currentStats.change.noTours > 0, 'text-danger': currentStats.change.noTours <= 0}"><i class="fa" :class="{'fa-arrow-up': currentStats.change.noTours > 0, 'fa-arrow-down': currentStats.change.noTours <= 0}"></i>{{currentStats.change.noTours.toFixed(0)}}%</span>
              <span>#{{currentStats.compare.noTours}}</span>
            </template>
          </stats-card>
        </div>
        <div class="col-xl-3 col-md-6">
          <stats-card v-if="projectedStats.current" :title="projectedStats.current.noTours + ' tours booked'"
                      type="gradient-green"
                      :sub-title="'€' + projectedStats.current.eurTours"
                      icon="ni ni-cart">

            <template slot="footer">
              <span class="text-nowrap mr-2" :class="{'text-success': projectedStats.change.eurTours > 0, 'text-danger': projectedStats.change.eurTours <= 0}"><i class="fa" :class="{'fa-arrow-up': projectedStats.change.eurTours > 0, 'fa-arrow-down': projectedStats.change.eurTours <= 0}"></i>{{projectedStats.change.eurTours.toFixed(0)}}%</span>
              <span>earn €{{projectedStats.compare.eurTours}}, </span>
              <span class="text-nowrap mr-2" :class="{'text-success': projectedStats.change.noTours > 0, 'text-danger': projectedStats.change.noTours <= 0}"><i class="fa" :class="{'fa-arrow-up': projectedStats.change.noTours > 0, 'fa-arrow-down': projectedStats.change.noTours <= 0}"></i>{{projectedStats.change.noTours.toFixed(0)}}%</span>
              <span>#{{projectedStats.compare.noTours}}</span>
            </template>
          </stats-card>
        </div>
        <div class="col-xl-3 col-md-6">
          <stats-card v-if="currentStats.current" :title="currentStats.current.noTransfers + ' transfers completed'"
                      type="gradient-blue"
                      :sub-title="'€' + currentStats.current.eurTransfers"
                      icon="ni ni-shop">

            <template slot="footer">
              <span class="text-nowrap mr-2" :class="{'text-success': currentStats.change.eurTransfers > 0, 'text-danger': currentStats.change.eurTransfers <= 0}"><i class="fa" :class="{'fa-arrow-up': currentStats.change.eurTransfers > 0, 'fa-arrow-down': currentStats.change.eurTransfers <= 0}"></i>{{currentStats.change.eurTransfers.toFixed(0)}}%</span>
              <span>earn €{{currentStats.compare.eurTransfers}}, </span>
              <span class="text-nowrap mr-2" :class="{'text-success': currentStats.change.noTransfers > 0, 'text-danger': currentStats.change.noTransfers <= 0}"><i class="fa" :class="{'fa-arrow-up': currentStats.change.noTransfers > 0, 'fa-arrow-down': currentStats.change.noTransfers <= 0}"></i>{{currentStats.change.noTransfers.toFixed(0)}}%</span>
              <span>#{{currentStats.compare.noTransfers}}</span>
            </template>
          </stats-card>
        </div>
        <div class="col-xl-3 col-md-6">
          <stats-card v-if="projectedStats.current" :title="projectedStats.current.noTransfers + ' transfers booked'"
                      type="gradient-blue"
                      :sub-title="'€' + projectedStats.current.eurTransfers"
                      icon="ni ni-shop">

            <template slot="footer">
              <span class="text-nowrap mr-2" :class="{'text-success': projectedStats.change.eurTransfers > 0, 'text-danger': projectedStats.change.eurTransfers <= 0}"><i class="fa" :class="{'fa-arrow-up': projectedStats.change.eurTransfers > 0, 'fa-arrow-down': projectedStats.change.eurTransfers <= 0}"></i>{{projectedStats.change.eurTransfers}}%</span>
              <span>earn €{{projectedStats.compare.eurTransfers}}, </span>
              <span class="text-nowrap mr-2" :class="{'text-success': projectedStats.change.noTransfers > 0, 'text-danger': projectedStats.change.noTransfers <= 0}"><i class="fa" :class="{'fa-arrow-up': projectedStats.change.noTransfers > 0, 'fa-arrow-down': projectedStats.change.noTransfers <= 0}"></i>{{projectedStats.change.noTransfers}}%</span>
              <span>#{{projectedStats.compare.noTransfers}}</span>
            </template>
          </stats-card>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--7">
      <div class="row">
        <div class="mt-5 mb-xl-0 col-xl-12">
          <div class="card">
            <div class="card-header bg-transparent">
              <div class="row align-items-center">
                <div class="col">
                  <h6 class="text-uppercase text-muted ls-1 mb-1">Stats per boat</h6>
                  <h5 class="h3 mb-0">Rentals & earnings</h5>
                </div>
              </div>
            </div>
            <div class="card-body">
              <el-table v-if="showTable" class="table-responsive"
                  header-row-class-name="thead-light"
                  :data="boats">
            <el-table-column label="Boat"
                             min-width="160px"
                             prop="name"
                             sortable>
                <template v-slot="{row}">
                    <div class="media align-items-center">
                        <a href="#" class="avatar rounded-circle mr-3">
                            <img alt="Image placeholder" :src="'https://www.hvarboats.com/img/boat/small/' + row.photo">
                        </a>
                        <div class="media-body">
                            <span class="font-weight-600 name mb-0 text-sm">{{row.name}}</span>
                        </div>
                    </div>
                </template>
            </el-table-column>
            <el-table-column label="Done"
                             prop="stats.current.eurTotal"
                             min-width="100px"
                             sortable>
                             <template v-slot="{row}">
                    <badge class="badge-dot mr-4" type="">
                        <span>€ {{parseFloat(row.stats.current.eurTotal).toFixed(2) }}</span>
                        <div>#{{row.stats.current.noTotal }}</div>
                    </badge>
                </template>
            </el-table-column>
            <el-table-column label="Booked"
                             prop="projectedStats.current.eurTotal"
                             min-width="100px"
                             sortable>
                             <template v-slot="{row}">
                    <badge class="badge-dot mr-4" type="">
                        <span>€ {{parseFloat(row.projectedStats.current.eurTotal).toFixed(2) }}</span>
                        <div>#{{row.projectedStats.current.noTotal }}</div>
                    </badge>
                </template>
            </el-table-column>
            <el-table-column label="Progress"
                             prop="stats.current.noTotal"
                             min-width="150px"
                             sortable>
                <template v-slot="{row}">
                    <div class="d-flex align-items-center" v-if="row.projectedStats.current.noTotal > 0">
                        <span class="completion mr-1">{{((row.stats.current.noTotal/row.projectedStats.current.noTotal)*100).toFixed(0)}}%</span>
                        <div>
                            <base-progress type="success" :value="(row.stats.current.noTotal/row.projectedStats.current.noTotal)*100"/>
                        </div>
                    </div>
                </template>
            </el-table-column>
            <el-table-column :label="currentStats.compare.year + ' total'"
                             prop="projectedStats.current.eurTotal"
                             min-width="130px"
                             sortable>
                             <template v-slot="{row}">
                    <badge class="badge-dot mr-4" type="">
                        <span>€ {{parseFloat(row.projectedStats.compare.eurTotal).toFixed(2) }}</span>
                        <div>#{{row.projectedStats.compare.noTotal }}</div>
                    </badge>
                </template>
            </el-table-column>
            <el-table-column label="CHNG"
                             prop="stats.change.eurTotal"
                             min-width="80px"
                             sortable>
                <template v-slot="{row}">
                    <badge class="badge-dot mr-4" type="">
                        <i :class="`bg-${row.stats.change.eurTotal >= 0 ? 'success' : 'danger'}`"></i>
                        <span class="status">{{row.stats.change.eurTotal}}%</span>
                    </badge>
                </template>
            </el-table-column>
        </el-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import Vue from 'vue'
  import RouteBreadCrumb from '@/components/Breadcrumb/RouteBreadcrumb';
  import StatsCard from '@/components/Cards/StatsCard';
  import { Table, TableColumn, DropdownMenu, DropdownItem, Dropdown} from 'element-ui'
  import { mapGetters } from 'vuex'
  import axios from '../../axios-auth.js'

  Vue.use(require('vue-moment'));

  export default {
    name: 'starter-page',
    components: {
      StatsCard,
      RouteBreadCrumb,
      [Table.name]: Table,
      [TableColumn.name]: TableColumn,
      [Dropdown.name]: Dropdown,
      [DropdownItem.name]: DropdownItem,
      [DropdownMenu.name]: DropdownMenu,
    },
    data() {
      return {
        currentStats: [],
        projectedStats: [],
        showTable: false
      };
    },
     computed: {
      ...mapGetters([
        'boats'
      ]),
      currentDate: {
        get() {
          return this.moment().format("YYYY-MM-DD")
        }
      }
    },
    async created () {
      this.$store.dispatch('fetchBoats');
      this.currentStats = await this.getStats(null, this.currentDate)
      //console.log(this.currentStats)
      this.projectedStats = await this.getStats(null, null);
      for (let boat of this.boats) {
        boat.stats = await this.getStats(boat.id, this.currentDate)
        boat.projectedStats = await this.getStats(boat.id, null)
        //console.log(boat);
      }
      this.showTable = true;
    },
    methods: {
      async getStats(boatInstanceId, cutoffDate) {
        //console.log(start, end)
              this.loading = true
              return axios.get('booking_stats/' + boatInstanceId + "/" + cutoffDate)
              .then(res => {
                this.loading = false
                return res.data.stats || []
              })
      }, 
    }
  };
</script>
<style>
  .starter-page {
    min-height: calc(100vh - 380px);
  }
</style>
