import Vue from 'vue'
import { abilitiesPlugin } from '@casl/vue'
import moment from 'moment'
import DashboardPlugin from './plugins/dashboard-plugin';
import App from './App.vue';
import { store, ability } from './store/store'

//import ability from './ability'
// router setup
//import router from './routes/router';
import router from "./routes/starterRouter";

import './registerServiceWorker'
Vue.config.productionTip = false
// plugin setup
Vue.use(DashboardPlugin);
Vue.use(abilitiesPlugin, ability)
// moment
Vue.prototype.moment = moment
/* eslint-disable no-new */
new Vue({
  render: h => h(App),
  router,
  store
}).$mount('#app')