<template>
  <div class="wrapper">
    <notifications></notifications>
    <side-bar title="Boka Boats booking manager" logo="img/brand/boka-boats-hvar-logo-252x72.png">
      <template slot="links">
        <sidebar-item v-if="$can('admin', 'Site')" :link="{ name: 'Dashboard', icon: 'ni ni-shop text-primary', path: '/dashboard' }"></sidebar-item>
        <sidebar-item
          v-if="$can('admin', 'Site')"
          :link="{
            name: 'Bookings',
            icon: 'ni ni-bullet-list-67 text-primary',
          }">
            <sidebar-item :link="{ name: 'Inquiries', path: '/bookings/inquiries' }"></sidebar-item>
            <sidebar-item :link="{ name: 'Offers', path: '/bookings/bookings/pending' }"></sidebar-item>
            <sidebar-item :link="{ name: 'Confirmed', path: '/bookings/bookings/confirmed' }"></sidebar-item>
          </sidebar-item>
        <sidebar-item :link="{ name: 'Calendar', icon: 'ni ni-calendar-grid-58 text-primary', path: '/calendar' }"></sidebar-item>
        <sidebar-item :link="{ name: 'Timeline', icon: 'fas fa-stream text-primary', path: '/timeline' }"></sidebar-item>
      </template>
    </side-bar>
    <div class="main-content">
      <dashboard-navbar></dashboard-navbar>
      <div @click="$sidebar.displaySidebar(false)">
        <fade-transition :duration="200" origin="center top" mode="out-in">
          <!-- your content here -->
          <router-view></router-view>
        </fade-transition>
      </div>
      <content-footer v-if="!$route.meta.hideFooter"></content-footer>
    </div>
  </div>
</template>
<script>
/* eslint-disable no-new */
import PerfectScrollbar from 'perfect-scrollbar';
import 'perfect-scrollbar/css/perfect-scrollbar.css';

function hasElement(className) {
  return document.getElementsByClassName(className).length > 0;
}

function initScrollbar(className) {
  if (hasElement(className)) {
    new PerfectScrollbar(`.${className}`);
  } else {
    // try to init it later in case this component is loaded async
    setTimeout(() => {
      initScrollbar(className);
    }, 100);
  }
}
import { mapGetters } from 'vuex'
import DashboardNavbar from './Navbar.vue';
import ContentFooter from './Footer.vue';
//import DashboardContent from '../Layout/Content.vue';
import { FadeTransition } from 'vue2-transitions';
import updateMixin from '../../mixin/updateMixin'

export default {
  components: {
    DashboardNavbar,
    ContentFooter,
    FadeTransition,
  },
  mixins: [updateMixin],
  created() {
      if (this.updateExists) {
        this.$notify({type: 'info', message: 'An update is available, click to update', clickHandler: this.refreshApp});
      }
  },
  methods: {
    initScrollbar() {
      let docClasses = document.body.classList;
      let isWindows = navigator.platform.startsWith('Win');
      if (isWindows) {
        // if we are on windows OS we activate the perfectScrollbar function
        initScrollbar('scrollbar-inner');

        docClasses.add('perfect-scrollbar-on');
      } else {
        docClasses.add('perfect-scrollbar-off');
      }
    }
  }
};
</script>
