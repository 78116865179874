<template>
  <router-view></router-view>
</template>

<script>
export default {
  created() {
    this.$store.dispatch('fetchBoatTypes')
    this.$store.dispatch('fetchBoats')
    this.$store.commit('INIT_STORE')
  }
}
</script>
