import axios from '../../axios-auth.js'

const state = {
    customer: {
        id: null,
        name: null,
        email: null,
        phone: null,
        hasLicense: null
    }
}

const mutations = {
    SET_CUSTOMER(state, customer) {
        // merge data with previous state
        state.customer = Object.assign({}, state.customer, customer)
    },
    CLEAR_CUSTOMER(state) {
        state.customer = {}
    }
}

const actions = {
    storeCustomer({ commit, state }) {
        return axios.post('add_customer', state.customer)
            .then(res => {
                if (res.status == 200) {
                    commit('SET_CUSTOMER', {
                        id: res.data.customerId
                    })
                    return {
                        success: true,
                        customer: state.customer
                    }
                }
            })
            .catch(error => {
                console.log(error)
                return {
                    success: false,
                    error: error
                }
            })
    },
    fetchCustomers({ commit, state }) {
        if (!state.idToken)
            return
        axios.get('users')
            .then(res => {
                console.log(res)
                const data = res.data
                const users = []
                for (let key in data) {
                    const user = data[key]
                    user.id = key
                    users.push(user)
                }
                console.log(users)
                commit('storeUser', users[0])
            })
            .catch(error => console.log(error))
    }
}

const getters = {
    customer(state) {
        return state.customer
    }
}

export default {
    namespace: true,
    state,
    mutations,
    actions,
    getters
}