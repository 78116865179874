import axios from 'axios'
import { store } from './store/store'

const baseDomain = 'https://www.hvarboats.com'
const baseURL = `${baseDomain}/api`

const instance = axios.create({
    baseURL
})

instance.interceptors.request.use(
    config => {
        const token = store.state.auth.idToken
        config.headers.Authorization = token
        return config
    }, error => Promise.reject(error))


export default instance;