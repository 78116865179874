import Vue from 'vue';
import Router from 'vue-router';
import DashboardLayout from '../views/Layout/Layout.vue';
import AuthLayout from '@/views/Pages/AuthLayout.vue';
// GeneralViews
import NotFound from '@/views/GeneralViews/NotFoundPage.vue';

import Dashboard from '../views/Layout/Dashboard.vue';
import { store } from '../store/store';


Vue.use(Router);

let entryUrl = null;

const guard = async(to, from, next) => {
    if (store.getters.isAuthenticated) {
        if (entryUrl) {
            const url = entryUrl;
            entryUrl = null;
            return next(url); // goto stored url
        } else {
            return next(); // all is fine
        }
    }

    await store.dispatch('tryAutoLogin')
        // we use await as this async request has to finish 
        // before we can be sure

    if (store.getters.isAuthenticated) {
        next();
    } else {
        entryUrl = to.path; // store entry url before redirect
        next('/login');
    }
}

const Home = () =>
    import ( /* webpackChunkName: "pages" */ '@/views/Pages/Home.vue');
const Login = () =>
    import ( /* webpackChunkName: "pages" */ '@/views/Pages/Login.vue');
const EditBooking = () =>
    import ( /* webpackChunkName: "edit_booking" */ '@/views/Bookings/EditBooking.vue');
const Inquiries = () =>
    import ( /* webpackChunkName: "inquiries" */ '@/views/Bookings/Inquiries.vue');
const Bookings = () =>
    import ( /* webpackChunkName: "bookings" */ '@/views/Bookings/Bookings.vue');
const Calendar = () =>
    import ( /* webpackChunkName: "calendar" */ '@/views/Calendar.vue');
const Timeline = () =>
    import ( /* webpackChunkName: "timeline" */ '@/views/Timeline.vue');

let authPages = {
    path: '/',
    component: AuthLayout,
    name: 'Authentication',
    children: [{
            path: '/home',
            name: 'Home',
            component: Home,
            meta: {
                noBodyBackground: true
            }
        },
        {
            path: '/login',
            name: 'Login',
            component: Login
        },
        /*{
          path: '/register',
          name: 'Register',
          component: Register
        },
        {
            path: '/lock',
            name: 'Lock',
            component: Lock
        },*/
        { path: '*', component: NotFound }
    ]
};
let bookingsPages = {
    path: '/bookings',
    component: DashboardLayout,
    redirect: '/bookings/inquiries',
    name: 'Bookings',
    beforeEnter: guard,
    children: [{
            path: 'booking/:id',
            name: 'booking',
            component: EditBooking
        },
        {
            path: 'inquiries',
            name: 'inquiries',
            component: Inquiries,
            /*meta: {
                navbarClasses: 'bg-secondary navbar-light',
                searchClasses: 'navbar-search-dark'
            }*/
        },
        {
            path: 'bookings/:status?',
            name: 'bookings',
            component: Bookings,
            props: true
        }
        /*{
                        path: 'bookings/:status?',
                        name: 'bookings',
                        components: { default: Bookings },
                        props: true
        },*/
    ]
};

export default new Router({
    routes: [{
            path: '/',
            name: 'home',
            redirect: '/calendar',
            component: DashboardLayout,
            beforeEnter: guard,
            children: [{
                    path: 'dashboard',
                    name: 'dashboard',
                    component: Dashboard
                },
                {
                    path: 'calendar',
                    name: 'calendar',
                    components: { default: Calendar }
                },
                {
                    path: 'timeline',
                    name: 'timeline',
                    component: Timeline
                }
            ]
        },
        bookingsPages,
        authPages
    ],
    scrollBehavior: (to, from, savedPosition) => {
        if (savedPosition) {
            return savedPosition;
        }
        if (to.hash) {
            return { selector: to.hash };
        }
        return { x: 0, y: 0 };
    }
});